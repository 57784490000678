.settings{
    position: absolute;
    bottom: 0;
    left: 90px;
    width: 300px;
    height: 450px;
    padding: 25px 0px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.settingsHided{
   display: none;
}

.settingsButton {
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;
    margin: 0 20px;
    cursor: pointer;
}

.settingsButton:hover {
    opacity: 0.7;
}

.deleteButton {
    background-color: #530429;
}