
.loginContainer {
    display: flex;
    width: 100%;
    height: 100%;
    color:black;
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 50%;
    min-width: 600px;
    height: 100%;
    background-color: white;
    justify-content: center;    
}

.loginFormCompanyName {
    display: flex;
    color: black;
    align-items: center;
    height: 30px;
}

.logincompanyName {
    margin:0px;
    font-size: 1.3rem;
    font-family: 'Cosmata';
    font-weight: 700;
}

.loginformCompanyNameDot {
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6219d3;
    margin-right: 20px;
}

.loginform {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: auto;
}

.loginformTitle {
    font-family: 'Cosmata Bold';
}

.loginformText, .loginHaveNotAccount {
    color: grey;
    height: 40px;
    line-height: 40px;
    font-size: 0.9rem;
}

.loginHaveNotAccount {
    width: 100%;
    display: flex;
    justify-content: center;
}

.loginRememberContainer {
    color: grey;
    height: 40px;
    line-height: 40px;
    font-size: 0.9rem;
    display: flex;    
}

.loginForgotPassword {
    margin-left: auto;
}

.loginformInputRow {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.loginformRowItem {
    width: 100%;
    margin-top:25px
}

.loginformButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    background-color: #6219d3;
    border-radius: 10px;
    height: 40px;
    margin:15px 0px;
    cursor: pointer;
}

.loginformButtonDisabled {
    composes: loginformButton;
    background-color: gray;
    cursor: not-allowed; 
}

.loginformTextField {    
    height: 40px;
    padding-top: 0!important;
    border-radius: 10px;
}

.loginerrorMsg {
    color:red;
    margin:0;
}

.loginformTextField input{    
    padding:0px 10px!important;
    height: 40px;
}

.loginLogoContainer {
    display: flex;
    width: 50%;
    height: 100%;
    min-width: 300px;
    background-color: #f4f6fc; 
    position: relative;   
}

.loginbcgLogo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    margin: auto;
    width: 70%;
    height: 100%;
}

.leftCompanyName {
    position: absolute;
    bottom: 4%;
    left: 50px;
    color: grey;
}
