
.registerContainer {
    display: flex;
    width: 100%;
    height: 100%;
    color:black;
}

.formContainer {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 67%;
    min-width: 600px;
    height: 100%;
    background-color: white;
    justify-content: center;    
}

.formCompanyName {
    display: flex;
    color: black;
    align-items: center;
    height: 30px;
}

.companyName {
    margin:0px;
    font-size: 1.3rem;
    font-family: 'Cosmata';
    font-weight: 700;
}

.formCompanyNameDot {
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6219d3;
    margin-right: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: auto;
}

.formTitle {
    font-family: 'Cosmata Bold';
}

.formText {
    color: grey;
    height: 40px;
    line-height: 40px;
    font-size: 0.9rem;
}

.formInputRow {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.formRowItem {
    width: 47%;
}

.formButton {
    width: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    background-color: #6219d3;
    border-radius: 10px;
    height: 40px;
    margin:15px 0px;
    cursor: pointer;
}

.formButtonDisabled {
    composes: formButton;
    background-color: gray;
    cursor: not-allowed; 
}

.formTextField {    
    height: 40px;
    padding-top: 0!important;
    border-radius: 10px;
}

.errorMsg {
    color:red;
    margin:0;
}

.formTextField input{    
    padding:0px 10px!important;
    height: 40px;
}

.logoContainer {
    display: flex;
    width: 33%;
    height: 100%;
    min-width: 300px;
    background-color: #f4f6fc; 
    position: relative;   
}

.bcgLogo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    margin: auto;
    width: 85%;
    height: 100%;
}

.rightCompanyName {
    position: absolute;
    bottom: 4%;
    right: 10%;
    color: grey;
}
