.tableContainer {
    font-family: 'Cosmata';
    width: 100%;
    height: 100vh;
    background-color: white;
    color: black;
    padding: 30px;
    position: relative;
}

.logoutAdminButton {
    position: fixed;
    top: 0;
    right: 30px;
    color: #6219d3;
    cursor: pointer;
    text-decoration: underline;
    line-height: 30px;
}