@font-face {
  font-family: 'Cosmata';
  src: url('fonts/Cosmata/Cosmata-Regular.otf') format('opentype'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url('fonts/Cosmata/Cosmata-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: 'Cosmata Bold';
  src: url('fonts/Cosmata/Cosmata-Bold.otf') format('opentype'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  url('fonts/Cosmata/Cosmata-Bold.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}


body {
  margin: 0;
  padding: 0;
  min-height: 700px;
  font-family: "Cosmata";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color:white;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a102c;
  z-index: 1330;
  opacity: 0.5;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(171, 171, 171); 
  cursor: pointer;
}

.App {
  display: flex;
  font-family: "Cosmata" !important;
}

main {
  padding: 10px;
}

.logoImg {
  width: 0px;
  height: 0px;
  position: fixed;
  top:0;
  left:-webkit-calc(50% - 150px);
  left:-moz-calc(50% - 150px);
  left:calc(50% - 150px);
  z-index: -1;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

input {
  line-height: 30px!important;
}

input::placeholder {
  padding: 0!important;;
  font-family: "Cosmata";
  font-size: 0.85rem;
  line-height: 30px!important;;
}

.m-b-20{
  margin-bottom: 20px;
}

a, a:hover, a:visited, a:active {
  color:#6219d3;
  text-decoration: none;
}

/*Media*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   /* width */
   ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
  }
}

@media only screen and (min-width: 600px) {

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey;
    border-radius: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
  }
}
@media only screen and (min-width: 850px) {

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }

}


/*Blink*/
.blinking {
  -webkit-animation: 1s blink linear infinite;
  -moz-animation: 1s blink linear infinite;
  -ms-animation: 1s blink linear infinite;
  -o-animation: 1s blink linear infinite;
  animation: 1s blink linear infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  from,
  to {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {

  from,
  to {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}