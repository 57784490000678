
.feedbackIconContainer {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 70px;
    height: 70px;
    background: #190637;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
}

.feedbackIcon {
    width: 35px;
    height: 35px;
}

.feedbackTitle {    
    font-weight: 700;
    color: #282828;
}

.feedbackDescription {
    color: #282828;
    font-size: 0.9rem;
}

.reportButton {
    height: 50px;
    color: #6219D3;
    text-align: center;
    border: 2px solid #6219D3;
    border-radius: 6px;
    background-color: #EADCFF;
    line-height: 50px;
    cursor: pointer;
    width: 100%;    
    margin-bottom: 35px;
}

.reportButton:hover, 
.actionCancelButton:hover, 
.actionSubmitButton:hover {
    opacity: 0.7;
    cursor: pointer;
}

.actionButton {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0px;
}

.actionCancelButton {
    background: #FFFFFF;
    border-radius: 6px;
    width: 50%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #656565;
}

.actionSubmitButton {
    background: #6219D3;
    border-radius: 6px;
    width: 50%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #FFFFFF;
}

.feedbackTextField {
    width: 100%;
    padding: 10px !important;
    background: #FFFFFF;
    border: 2px solid #D2D2D2;
    border-radius: 6px;
    font-size: 15px;
    line-height: 18px;
    color: #282828;
    resize: none;
    margin: 10px 0px 20px;
}

.feedbackTextField:focus-visible {
    outline: none;
}

textarea::placeholder {
    color: white !important;
}

.feedbackDialog, .bugReportDialog {
    font-family: 'Cosmata';
    color: #282828;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px white;
        border-radius: 2px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #D2D2D2;
        border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #D2D2D2;
        cursor: pointer;
    }
}


.ratingContainer {
    color: #FFC84E;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 30px 0 30px 10px;

    label {
        color: #FFC84E!important;

        .MuiSvgIcon-root {
            width: 1.5em!important;
            height: 1.5em!important;
        }
    }    
}

.feedbackRatingIcon {
    width: 30px;
    height: 30px;
}

.feedbackRatingEmptyIcon {
    width: 30px;
    height: 30px;
}