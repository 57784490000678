.tutorialContainer {
  position: fixed;
  top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Cosmata';
  z-index: 1;
}

.tutorialDisabledContainer {
  position: fixed;
  top: 30px;
  width: 100%;
  height: 100px;
  z-index: 10;
}

.viewIconContainer {
  position: fixed;
  top: 50px;
  right: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.viewIcon {
  width: 30px;
  height: 30px;
}

.tutorialStepper {
  width: 100%;
  max-width: 700px;
  min-width: 500px;
}

.tutorialStepperClosed {
  display: none;
}



/*Stepper*/
.stepsContainer {
  display: flex;
  padding-top: 20px;
}


.step {
  text-align: center;
  flex: 1;
  cursor: pointer;
  position: relative;
}

.step:first-child {
  cursor: default;
}

.step .stepLine {
  height: 0px;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% + 22px);
  width: calc(100% - 44px);
}

.step .stepCircle {
  margin: 0 auto;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 12px;
  color: #fff;
}

.stepActive .stepCircle {
  background: #6219D3;
}

.stepDone .stepCircle {
  background: #6219D3;
}

.stepInactive .stepCircle {
  background: #A6A6A6;
  cursor: pointer;
}

.publishInactive .stepCircle {
  border: 2px solid #6219D3;
  cursor: pointer;
}

.publishInactive .stepLabel {
  color: #6219D3;
}

.stepActive .stepLabel {
  color: #6219D3;
}

.stepDone .stepLabel {
  color: #6219D3;
}

.stepInactive .stepLabel {
  color: #A6A6A6;
}

.stepDone .stepLine {
  border-color: #6219D3;
}

.stepIcon {
  width: 25px;
    height: 25px;
}

.stepInactive .stepLine,
.stepActive .stepLine {
  border-color: #A6A6A6;
  opacity: 0.4;
}