.assets{
    position: absolute;
    top: 0;
    left: 90px;
    width: 300px;
    height: 100%;
    padding: 0 20px;
    padding-top: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
}

.folowCursorContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.imgItemMoveCursorIcon {
    position: absolute;
    width: 60px;
    height: 60px;
    pointer-events: none;
}

.folowCursorContainerHided {
    
}

.assetsHide {
    composes: assets;
    display: none;
}

.accordionImg {
    width: 26px;
    height: 26px;
    margin-right: 15px;
}

.accordionHeader {
    display: flex;
    line-height: 30px;
}

.imgContainer {
    width: 120px;
    height: 120px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    cursor: grab;
    position: relative;
}

.imgCursorContainer {
    width: 120px;
    height: 120px;
    display: flex;
    background-color: #ffffffa8;
    border-radius: 10px;
    cursor: grab;
    position: relative;
}

.imgItem {
    width: 100px;
    height: 100px;
    margin: auto;
    display: block;
}

.imgItemCursorIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    pointer-events: none;
}

.searchTextField {    
    height: 30px;
    color: lightgray;
    padding-top: 0!important;
}

.searchTextField input{    
    padding:0px !important;
    height: 30px;
}

.searchContainer {
    height: 30px;
    color:lightgray;
    display: flex;
    border-radius: 30px;
    background-color: #594284;    
    line-height: 30px;
    align-items: center;
    margin-bottom: 10px;
}
