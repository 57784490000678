.cancelDeleteButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;
    margin-right: 15px;
}

.yesDeleteButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #530429;
    line-height: 45px;
    cursor: pointer;
    width: 20%;    
}

.cancelDeleteButton:hover,
.yesDeleteButton:hover {
    opacity: 0.7;
}