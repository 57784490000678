.requestVideoChatTitle {    
    font-weight: 700;
    color: #282828;
    text-align: center;
}

.requestVideoChatDescription {
    color: #282828;
    font-size: 0.9rem;
    margin-top: 15px;
    text-align: center;
}

.requestVideoChatDialog {
    font-family: 'Cosmata';
    color: #282828;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px white;
        border-radius: 2px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #D2D2D2;
        border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #D2D2D2;
        cursor: pointer;
    }
}

.actionRequestContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: 384px;
    margin: 30px 0px;
}

.actionRequestCancelButton {
    background: #FFFFFF;
    border-radius: 6px;
    width: 180px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #656565;
    cursor: pointer;
}

.actionRequestJoinButton {
    background: #6219D3;
    border-radius: 6px;
    width: 180px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #FFFFFF;
    cursor: pointer;
}

.actionRequestCancelButton:hover, 
.actionRequestJoinButton:hover {
    opacity: 0.7;
}
