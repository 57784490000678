.promoteStoreDialog {
    font-family: 'Cosmata';
}

.promoteInputContainer {
    height: 50px;
    width: 100%;
}

.promoteLabel {
    height: 35px;
    width: 578px;
    text-align: center;
}

.bugEmailTextField {
    height: 40px;
    color: lightgray;
    padding-top: 0px !important;
    line-height: 40px;    
    width: 100%;
}

.bugEmailTextField input {
    padding: 0px 15px !important;
    border-radius: 10px;
    background-color: #594284;
    height: 40px;
    color: lightgray !important;
}

.bugEmailTextField input::placeholder { 
    color: white !important;
    opacity: 1;
}

.bugMessageTextField {
    width: 100%;
    padding: 15px !important;
    border-radius: 10px;
    background-color: #594284;   
    color: lightgray !important;
    resize: none;
    border: none;
    margin-top: 20px;
}

.bugMessageTextField:focus-visible {
    outline: none;
}

textarea::placeholder {
    color: white !important;
}

.sendBugButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;
    margin: 10px auto 0px;
    margin-bottom: 35px;
}

.sendBugButton:hover {
    opacity: 0.7;
}



