.promoteStoreDialog {
    font-family: 'Cosmata';
}

.promoteInputContainer {
    height: 50px;
    width: 100%;
    display: flex;
}

.promoteInputContainer:last-of-type {
    margin-bottom: 35px;
}

.promoteLabel {
    height: 45px;
    width: 100%;
    line-height: 45px;
}

.promoteShareLink {
    height: 40px;
    color: lightgray;
    text-align: left;
    padding: 0 10px;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;
    margin-right: 10px;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.promoteCopyButton {
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;
    cursor: pointer;
    width: 20%;
}

.promoteCopyButton:hover {
    opacity: 0.7;
}

.promoteUrlTextField {
    height: 40px;
    color: lightgray;
    padding-top: 0px !important;
    line-height: 40px;
    margin-right: 15px;
    width: 80%;
}

.promoteUrlTextField input {
    padding: 0px 10px !important;
    border-radius: 10px;
    background-color: #594284;
    height: 40px;
    color: lightgray !important;
    -webkit-text-fill-color: lightgray !important;
}

.promoteUrlTextField input.Mui-disabled {
    -webkit-text-fill-color: lightgray !important;
}

.promoteSpacesButton {
    height: 45px;
    width: 135px;
    margin-right: 15px;
}

.promoteDownloadButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;
}

.promoteDownloadButton:hover {
    opacity: 0.7;
}

a.promoteDownloadButton:link {
    color:white;
    text-decoration: none;
}

a.promoteDownloadButton:visited {
    color:white;
    text-decoration: none;
}

a.promoteDownloadButton:hover {
    color:white;
    text-decoration: none;
}

a.promoteDownloadButton:active {
    color:white;
    text-decoration: none;
}