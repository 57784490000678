.billingStoreDialog {
    font-family: 'Cosmata';
}

.billingStoreDialogHide {
    display: none;
}

.billingInputContainer {
    display: flex;
    width: 100%;
    height: 50px;
}

.upgradePlanDescription {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
}

.billingPaymentImgContainer {
    width: 50px;
    margin-right: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.billingPaymentImg {
    width: 45px;
    border-radius: 10px;
}

.billingStorePaymentDescription {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.85rem;
}

.changePaymentButton  {
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;
    cursor: pointer;
    width: 20%;
    margin: 20px 0px;
}

.changePaymentButton:hover {
    opacity: 0.7;
}

.billingLabel {
    height: 35px;
    width: 100%;
    font-family: 'Cosmata Bold';
}

.billingContainer {
    display: flex;
    flex-direction: column;
    padding: 25px 0px;

}

.billingStorePlanContainer {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
}

.billingStoreSizeContainer {
    width: 300px;
    height: 80px;    
    position: relative;
    border-radius: 10px;    
    display: flex;
    cursor: pointer;
    background-color: white;
    opacity: 0.35;
}

.billingStoreSizeContainer:last-of-type {
    margin-left: 35px;
}

.billingStoreSizeCheckbox {
    position: absolute!important;
    top: 0px;
    left: 0px;    
}

.billingStoreSizeImgContainer {
    width: 50px;
    margin: 0 20px 0 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.billingStoreSelectedSize {
    opacity: 1;
}

.billingStoreSizeImg {
    width: 45px;
}

.billingStoreSizeDescription {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.7rem;
}

.billingStoreSizeDescriptionTitle {
    font-family: 'Cosmata Bold';
    font-size: 0.9rem;
}

/*AI plan*/
.billingStoreAIPlanContainer {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;
}

.billingStoreAIPlanBox {
    width: 195px;
    height: 90px;
    position: relative;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    background-color: white;
    opacity: 0.35;
    padding: 0 23px 0 35px;
}

.billingStoreAIPlanCheckbox {
    position: absolute!important;
    top: 0px;
    left: 0px;    
}

.billingStoreSelectedAIPlan {
    opacity: 1;
}

.billingStoreAIPlanDescription {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.7rem;
}

.billingStoreAIPlanDescriptionTitle {
    font-family: 'Cosmata Bold';
    font-size: 0.9rem;
}

.cancelBillingPlanButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;
    margin-right: 15px;
}

.proceedBillingPlanButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;    
}

.cancelBillingPlanButton:hover,
.proceedBillingPlanButton:hover {
    opacity: 0.7;
}

