.wallart{
    position: absolute;
    top: 0;
    left: 90px;
    width: 310px;
    height: 100%;    
    padding-top: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
}

.wallartHide {
    composes: wallart;
    display: none;
}

.artTitle{
    padding: 0 20px;
}

.artUrlTextField {    
    height: 30px;
    color: lightgray;
    padding-top: 0px!important;
}

.artUrlTextField input {
    padding:0px 10px !important;
    border-radius: 30px;
    background-color: #594284;
    height: 30px;
}

.artUrlInputContainer {
    height: 30px;
    color:lightgray;
    display: flex;     
    line-height: 30px;
    align-items: center;
}

.artUrlContainer {    
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.artUrlContainerLabel{
    margin: 0;
    font-size: 0.9rem;
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

label {
    margin-bottom: 15px;    
    color: gray;
}

.artSection{    
    height: 95px;
    width: 100%;
}

.artItemContainer {
    display: flex;
    height: 70px;
}

.art {
    width: 70px;
    min-width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}

.artIcon {
    width: 35px;
    max-height: 35px;
}

.artImage {
    max-width: 100%;
    max-height: 100%;
}

.iconArtContainer {
    display: flex;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    background-color: #3b2561;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.artLoading {
    margin-left: 20px;
}