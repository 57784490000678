
.cameraModesContainer {
    position: fixed;
    right: 200px;
    bottom: 20px;
    width: 108px;
    height: 60px;
    background: #190637;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.cameraIconContainer {   
    width: 48px;
    height: 48px;    
    display: flex;
    justify-content: center;
    align-items: center;   
    cursor: pointer;
    opacity: 0.4;
    border-radius: 10px;
}

.cameraIconContainerActive {
    composes: cameraIconContainer;
    background-color: #462F6A;
    opacity: 1;
}

.cameraIcon {
    width: 30px;
    height: 30px;
}


@media (orientation: portrait) and (max-width: 550px){
    .cameraModesContainer {        
        right: 20px;
        bottom: 110px;
    }
  }