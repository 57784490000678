.avatarsTitle {
    font-weight: 700;
    color: #282828;
    text-align: center;
}


.avatarsApplyButton:hover {
    opacity: 0.7;
    cursor: pointer;
}

.actionAvatarsButton {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 30px 0px 0px 0px;
}

.avatarsApplyButton {
    background: #6219D3;
    border-radius: 6px;
    width: 150px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #FFFFFF;
}

.avatarsContainer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: auto;
    flex-wrap: no-wrap;
    overflow-x: auto;
    padding-bottom: 10px;
    margin-top: 20px;
}

.avatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    width: 130px;
    height: 300px;
    position: relative;
    cursor: pointer;
}

.avatarImg {
    height: 280px;
}

.avatarCheckbox {
    position: absolute!important;
    top: 5px;
    right: 5px;
    padding: 1px!important;
}



/* Track */
.avatarsContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgb(43, 43, 43);   
}

/* Handle */
.avatarsContainer::-webkit-scrollbar-thumb {
    background: #9d9d9d;
}

.avatarsDialog {
    font-family: 'Cosmata';
    color: #282828;
}