.sidenav {
    width: 230px;
    transition: width 0.2s ease-in-out;    
    background-color:#1a102c;
    position: absolute;
    z-index: 2;
    top: 50px;
    left: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px 15px;
    min-height: 630px;
}

.sidenavClosed {
    composes: sidenav;
    transition: width 0.2s ease-in-out;
    width: 90px;  
}

.sidenavActiveItem {
    composes: sidenav;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.sidenavBottomActiveItem {
    composes: sidenav;
    border-bottom-right-radius: 0px;
}

.toolContainer{
    margin-top: 15px;
    background-color: #2f125e;
    border-radius: 10px;
    padding: 15px 0px;
}

.actionContainer {
    padding: 15px 0px;
    margin-top: auto;
}

.sideitem {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
    opacity: 0.6;
    min-height: 48px;
    padding: 0 15px;
}

.sideitemSelected {
    composes: sideitem;
    background-color: #3b2561;
    border-radius: 10px;
    opacity: 1;
    color: white;
}

.tutorialSelected {
    background-color: #6219D3;
    border-radius: 10px;
    opacity: 1;
    color: white;
}

.sideimg{
    width: 26px;
    height: 26px;
}

.linkText {
    padding-left: 16px;
}

.linkTextClosed {
    composes: linkText;
    visibility: hidden;
}

.sideitem:hover {
    background-color: #3b2561;
    border-radius: 10px;
    opacity: 1;
    color: white;
}
.menuBtn {
    background-color: transparent;    
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: 2px solid gray;
    border-radius: 10px;
    align-self: flex-start;
}
.delete{
    position: absolute;
    bottom: 0;
    left: 90px;
    width: 300px;
    height: 155px;
    padding: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
    display: flex;
}

.deleteContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.deleteDescription {
    color: white;
    opacity: 0.6;
    font-size: 0.95rem;
}

.deleteActions {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 100px;
}

.deleteHide{
    display: none;
}

.deleteActionButton {
    margin: 7px 0px;
    width: 75px;
    height: 45px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    text-align: center;
    background-color: #3b2561;
    border-radius: 10px;
}

.deleteActionButton:hover {    
    opacity: 0.7;
}