.myproducts{
    position: absolute;
    top: 0;
    left: 90px;
    width: 310px;
    height: 100%;    
    padding-top: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
    display: flex;
    flex-direction: column;
}

.myproductsHide {
    composes: myproducts;
    display: none;
}

.productTitle {
    padding: 0 20px;
}

.searchTextField {    
    height: 30px;
    color: lightgray;
    padding-top: 0px!important;
}

.searchTextField input{    
    padding:0px 10px 0px 0px!important;
    height: 30px;
}

.searchContainer {
    height: 30px;
    color:lightgray;
    display: flex;
    border-radius: 30px;
    background-color: #594284;    
    line-height: 30px;
    align-items: center;
    margin: 10px 20px;
}

.productUrlTextField {    
    height: 30px;
    color: lightgray;
    padding-top: 0px!important;
}

.productUrlTextField input {
    padding:0px 10px !important;
    border-radius: 30px;
    background-color: #594284;
    height: 30px;
}

.productUrlInputContainer {
    height: 30px;
    color:lightgray;
    display: flex;     
    line-height: 30px;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    
}

.productUrlContainer {    
    margin-left: 10px;
    display: flex;
    align-items: center;
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

label {
    margin-bottom: 15px;    
    color: gray;
}

.productSection{    
    height: 90px;    
    width: 100%;
}

.prodTooltip {
    margin-left: 20px!important;
}

.newProductSection {
    min-height: 90px;
    height: 90px; 
    width: 100%;
    padding: 0 10px;
}

.newProductSection:first-of-type {
    margin-top: 17px;
}

.productSectionHided {
    composes: productSection;
    display: none;

}

.productItemContainer {
    display: flex;
    height: 70px;
}

.product {
    width: 70px;
    min-width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}

.productIcon {
    width: 35px;
    max-height: 35px;
}

.productImage {
    max-width: 100%;
    max-height: 100%;
}

.iconProductContainer {
    display: flex;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    background-color: #3b2561;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.productsScrollContainer {
    overflow-y: auto;
    margin-bottom: 15px;
}

.productsScrollContainerBordered {    
    composes: productsScrollContainer;
    position: relative;
    border: 2px dashed #837C9D;
    height: 100%;
    margin: 10px 10px 25px 10px;
    border-radius: 20px;   
    display: flex;
    flex-direction: column;
}

.addButton {
    height: 40px;
    min-height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    margin: auto 20px 15px 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addProductsIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.addButton:hover {
    opacity: 0.7;
}

.productLoading {
    margin-left: 20px;
}

.cotoutDialogContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.cotoutDialogDescription {
    margin: 20px 0px;
}

.cotoutDialogCotoutButton, 
.cotoutDialogContinueButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;
    margin: 0 auto;    
}

.cotoutDialogCotoutButton:hover, 
.cotoutDialogContinueButton:hover {
    opacity: 0.7;
}

.dropArea {
    display: flex;
    height: 100%;
    width: 280px;
    margin: 0px;
    color: white;
}

.dropArea input {
    width: 100%;    
}

.fileDropZone {
    width:100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
}

.fileDropZoneCloseIconContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 34px;
    height: 34px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.fileUploadIconContainer {
    width:50px;
    height: 50px;
    margin-bottom: 15px;
}

.fileUploadIcon{
    width:45px;
    height: 45px;
}

.fileUploadTitle {
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
}
