
.videoChatIconContainer {
    position: fixed;
    right: 110px;
    bottom: 20px;
    width: 70px;
    height: 70px;
    background: #190637;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
}

.videoChatIcon {
    width: 35px;
    height: 35px;
}

.videoChatTitle {    
    font-weight: 700;
    color: #282828;
}

.videoChatDescription {
    color: #282828;
    font-size: 0.9rem;
    margin-top: 15px;
}

.videoChatDialog {
    font-family: 'Cosmata';
    color: #282828;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px white;
        border-radius: 2px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #D2D2D2;
        border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #D2D2D2;
        cursor: pointer;
    }
}

.videoChatInputContainer {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 20px 0px;
}

.requestOwnerMeetingContainer {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 20px 0px;
    position: relative;
    cursor: pointer;    
}

.requestOwnerMeetingContainerNotAllowed {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 20px 0px;
    position: relative;
    cursor: not-allowed;
    opacity: 0.5;
}

.requestOwnerMeetingContainer:hover {    
    opacity: 0.7;
}

.actionVideoChatButtonContainer:hover {
    opacity: 0.7;
    cursor: pointer;
}

.actionVideoChatButton {
    display: flex;
    width: 100%;
    justify-content: center;    
}

.actionVideoChatButtonContainer {
    background: #6219D3;
    border-radius: 6px;
    width: 150px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #FFFFFF;
    margin: 0px auto 10px;
}

/* Extra small devices (phones, 728px and down) */
@media only screen and (max-width: 850px) {
    .videoChatInputContainer {        
        margin: 15px 0px;
    }
}

.videoChatLinkIcon, 
.requestOwnerIcon {
    width: 24px;
    height: 24px;
    margin: 0 15px;
}

.requestOwnerText {
    height: 100%;
    width: calc(100% - 100px);
    display: inline-block;
    line-height: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
   
}

.videoChatCopyButton {
    height: 40px;
    color: #282828;
    text-align: center;    
    line-height: 40px;
    cursor: pointer;
    width: 80px;
    background: #E6D9FA;
    border-radius: 6px;
}

.requestOwnerCheckbox {
    position: absolute!important;
    top: 15px;
    right: 15px;
    padding: 1px!important;
    cursor: not-allowed;
}

.videoChatCopyButton:hover {
    opacity: 0.7;
}

.videoChatUrlTextField {
    height: 40px;
    color: #656565;
    padding-top: 0px !important;
    line-height: 40px;
    margin-right: 15px;
    width: 255px;
}

.videoChatUrlTextField input {    
    height: 40px;
    color: #656565!important;
    -webkit-text-fill-color: #656565!important;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.videoChatUrlTextField input.Mui-disabled {
    -webkit-text-fill-color: #656565!important;
}
