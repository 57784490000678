.customisation{
    position: absolute;
    top: 0;
    left: 90px;
    width: 300px;
    height: 100%;    
    padding-top: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
}

.customisationHide {
    composes: customisation;
    display: none;
}

.customiseTitle {
    padding: 0 20px;
    margin-bottom: 18px;
}

.wallColorSection, .floorColorSection, .accentColorSection {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    min-height: 190px;
}

.floorColorSection {
   
    min-height: 100px;
}

label {
    margin-bottom: 15px;    
    color: gray;
}

.colorContainer {
    border-radius: 10px;
    width: 55px;
    height: 55px;
    cursor: pointer;
}

.colorImgItem{
    width: 55px;
    height: 55px;
    border-radius: 10px;
}

.colorCheckbox {
    position: absolute!important;
    top: 0px;
    left: 0px;
    padding: 1px!important;
}

.colorCheckboxHide{
    composes: colorCheckbox;
    display: none!important;
}

.customiseTabs {
    font-family: 'Cosmata';
    width: 265px;
    margin: 0 20px;
    height: 30px;
    line-height: 24px;
    font-size: 14px;
    background-color: rgba(131, 124, 157, 0.25);
    border-radius: 20px;
    padding:3px;
}

.customiseTabList {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;
    color: rgba(131, 124, 157, 1);

}

.customiseTabList :nth-of-type(1) {
    width:66px;
}
.customiseTabList :nth-of-type(2) {
    width: 89px;
}
.customiseTabList :nth-of-type(3) {
    width: 103px;
}

.customiseTabSelected {
    background: #6219D3;
    border-radius: 17px;
    color: white;
    outline: none;
}

.customiseTab {
    padding: 0;
    cursor: pointer;
}

.customiseTabPanel {
    padding: 0;
}