.closePPButton {
    height: 45px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 45px;
    cursor: pointer;
    width: 20%;
    margin: 10px auto 0px;
}

.closePPButton:hover {
    opacity: 0.7;
}