.setupStoreContainer {
    display: flex;
    width: 100%;
    height: 100%;
    color:black;
    background-color: #f4f6fc;
    flex-direction: column;
    padding: 50px;
    min-width: 700px;
    min-height: 500px;
}

.setupStoreCompanyName {
    display: flex;
    color: black;
    align-items: center;
    height: 30px;
}

.setupStoreCompanyName {
    margin:0px;
    font-size: 1.3rem;
    font-family: 'Cosmata';
    font-weight: 700;
}

.setupStoreCompanyNameDot {
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6219d3;
    margin-right: 20px;
}

.setupStoreFormContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
}

.setupStoreForm {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
}

.setupStoreStep {
    margin: auto;    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.setupStoreFooterButtons {
    margin-top: auto;
    display: flex;
    width: 100%;
    color: grey;
    padding: 0 5%;
}

.setupStoreNextButton {
    margin-left: auto;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
}

.setupStoreBackButton {
    margin-right: auto;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
}

.setupStoreNextButton:hover, 
.setupStoreBackButton:hover {
    opacity: 0.75;
}

.storeNameStepTitle {
    font-family: 'Cosmata Bold';
}

.storeNameStepDescription {
    color:grey;
    font-size: 0.85rem;
    margin-bottom: 15px;
    text-align: center;
}

.storeNameStepInput {
    display: flex;
}

.setupStoreTextField {
    height: 50px;
    padding-top: 0!important;
    border-radius: 7px;
    margin-right: 15px;
    width: 350px!important;
    background-color: white;
}

.setupStoreTextField input{    
    padding:0px 10px!important;
    height: 50px;
}

.setupStoreProceedButton {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #6219d3;
    border-radius: 7px;
    height: 50px;
    cursor: pointer;    
}

/*Store Size*/

.setupStoreSizeContainer {    
    margin-bottom: 35px;
    width: 300px;
    height: 125px;
    box-shadow: 0px 0px 7px lightgrey;
    position: relative;
    border-radius: 7px;    
    display: flex;
    cursor: pointer;    
}

.setupStoreSizeContainer:nth-of-type(1) {
    margin-bottom: 20px; 
}

.setupStoreSizeCheckbox {
    position: absolute!important;
    top: 0px;
    left: 0px;    
}

.setupStoreSizeImgContainer {
    width: 50px;
    margin: 0 20px 0 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setupStoreSelectedSize {
    background-color: white;
}

.setupStoreSizeImg {
    width: 45px;
}

.setupStoreSizeDescription {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.7rem;
}

.setupStoreSizeDescriptionTitle {
    font-family: 'Cosmata Bold';
    font-size: 0.9rem;
}



/*Store Theme*/
.storeThemeStepContainer {
    display: flex;
    justify-content: space-between;
    min-width: 1070px;
    width: auto;
}

.setupStoreThemeItem {
    margin-bottom: 35px;
    width: 250px;
    height: 300px;
    box-shadow: 0px 0px 7px lightgrey;
    position: relative;
    border-radius: 7px;    
    display: flex;
    cursor: pointer;
    flex-direction: column;
    opacity: 0.4;
    background-color: white;
}

.setupStoreSelectedTheme {
    opacity: 1;
}

.setupStoreThemeImgContainer {
    width: 100%;    
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setupStoreThemeImg {
    width: 100%;
}

.setupStoreThemeDescription {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.8rem;
    align-items: center;
    color: gray;
    padding: 0px 35px;
    text-align: center;
}

.setupStoreThemeDescriptionTitle {
    font-family: 'Cosmata Bold';
    font-size: 1rem;
    color: black;
}

/*Store Setup Logo Step*/
.setupStoreFileNameContainer {
    width:400px;
    height: 175px;    
    display: flex;    
    justify-content: center;
    align-items: center;
    color: #6219d3;
}

.setupStoreFileDropZone {
    width:400px;
    height: 175px;
    border-radius: 7px;
    border: 2px dashed #6219d3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
}

.setupStorefileUploadIconContainer {
    width:50px;
    height: 50px;
    margin-bottom: 15px;
}

.fileUploadIcon{
    width:45px;
    height: 45px;
}

.fileUploadTitle {
    color: black;
    font-weight: 700;
}

.storeLogoStepInput {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    font-weight: 700;
}

.setupStoreLogoTextField {
    height: 40px;
    padding-top: 0!important;
    border-radius: 7px;
    margin: 5px 0px 30px 0;
    width: 400px!important;
    background-color: white;
}

.setupStoreLogoTextField input{    
    padding:0px 10px!important;
    height: 40px;
}