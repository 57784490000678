.mystore{
    position: absolute;
    top: 0;
    left: 90px;
    width: 310px;
    height: 100%;    
    padding-top: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
}

.mystoreTitle{
    padding: 0 20px;
}

.myStoreScrollContainer {
    overflow-y: auto;
    margin-bottom: 15px;
    max-height: calc(100vh - 170px);
    overflow-x: hidden;
    height: 'auto';
    min-height: 480px;
}

.mystoreHide {
    composes: mystore;
    display: none;
}


.searchTextField, .urlTextField {    
    height: 30px;
    color: lightgray;
    padding-top: 0px!important;
}

.searchTextField input{    
    padding:0px 10px !important;
    height: 30px;
}

.urlTextField input {
    padding:0px 10px !important;
    border-radius: 30px;
    background-color: #594284;
    height: 30px;
}

.searchContainer {
    height: 30px;
    color:lightgray;
    display: flex;
    border-radius: 30px;
    background-color: #594284;    
    line-height: 30px;
    align-items: center;
}

.urlInputContainer {
    height: 30px;
    color:lightgray;
    display: flex;     
    line-height: 30px;
    align-items: center;
}

.urlContainer {    
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.urlContainerLabel{
    margin: 0;
    font-size: 0.9rem;
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

label {
    margin-bottom: 15px;    
    color: gray;
}

.storeNameSection{
    border-bottom: 1px solid gray;
    height: 120px;
    width: 100%;
}

.logoSection{
    border-bottom: 1px solid gray;
    height: 150px;
    width: 100%;
}

.sizeSection{
    height: 280px;
    width: 100%;
    border-bottom: 1px solid gray;
}

.storeLogoContainer {
    display: flex;
    height: 70px;
}

.storeLogo {
    min-width: 70px;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}

.imgItem {
    width: 40px;
    max-height: 40px;
}

.iconStoreContainer {
    display: flex;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    background-color: #3b2561;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.storeSizeContainer {
    width: 100%;
    height: 90px;
    position: relative;
    border-radius: 10px;
    background-color: white;
    display: flex;
    cursor: pointer;
    opacity: 0.6;
}

.storeSizeContainer:nth-of-type(1) {
    margin-bottom: 20px; 
}

.storeSizeCheckbox {
    position: absolute!important;
    top: 0px;
    left: 0px;
}

.storeSizeImgContainer {
    width: 50px;
    margin: 0 20px 0 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedSize {
    opacity: 1;
}

.storeSizeImg {
    width: 45px;
}

.storeSizeDescription {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.7rem;
}

.storeSizeDescriptionTitle {
    font-family: 'Cosmata Bold';
    font-size: 0.9rem;
}


/*Store Theme*/
.storeThemeSection {
    margin-top: 20px;

}

.storeThemeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    width: auto;
}

.themeItem {
    margin-bottom: 35px;
    width: 100%;
    height: 200px;    
    position: relative;
    border-radius: 10px;    
    opacity: 0.6;
    display: flex;
    cursor: pointer;
    flex-direction: column;   
    background-color: white;
}

.selectedTheme {
    opacity: 1;
}

.themeCheckbox {
    position: absolute!important;
    top: 0px;
    left: 0px;
}

.themeImgContainer {
    width: 100%;    
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.themeImg {
    height: 130px;
    width: 100%;
}

.themeDescription {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 0.7rem;
    align-items: center;
    color: gray;
    padding: 0px 15px;
    text-align: center;
}

.themeDescriptionTitle {
    font-family: 'Cosmata Bold';
    font-size: 0.9rem;
    color: black;
}
