.publish{
    position: absolute;
    bottom: 0;
    left: 90px;
    width: 310px;
    height: 300px;
    padding: 20px;
    background-color: #2f125e;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid gray;
    display: flex;
    flex-direction: column;    
}

.publishTooltip {
    margin-left: 144px!important;
}

.publishButton {
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;    
    cursor: pointer;
    width: 125px;
    margin-bottom: 15px;
}

.publishButton:hover {
    opacity: 0.7;
}

.linkContainer {
    display: flex;
}

.publishDescription {
    color: lightgray;
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 15px;
}

.shareLink {
    height: 40px;
    color: lightgray;
    text-align: left;
    padding: 0 10px;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;
    margin-right: 10px;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.copyButton {
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: #594284;
    line-height: 40px;    
    cursor: pointer;
    width: 30%;
}

.copyButton:hover {
    opacity: 0.7;
}