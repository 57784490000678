.chatbot {   
    justify-content: center;
    transition: width 0.3s ease-in-out;
    position: fixed;
    z-index: 3;
    top: 20px;
    right: 0px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    width:0;
    font-family: 'Cosmata';
}

.chatbotOpen {
    composes: chatbot;
    transition: width 0.3s ease-in-out;
    width: 370px;
}

.chatBotContainer {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 340px;
    padding: 35px 0px;
    position: relative;
    min-height: 100px;
}

.chatPreviewText {
    filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.4));
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .chatbot {       
        top: 10px;       
        bottom: 10px;
    }
    
    .chatbotOpen {      
        width: 250px;
    }
    
    .chatBotContainer {       
        width: 235px;        
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .chatbot {       
        top: 10px;       
        bottom: 10px;
    }
    
    .chatbotOpen {      
        width: 290px;
    }
    
    .chatBotContainer {       
        width: 275px;        
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .chatbot {       
        top: 10px;       
        bottom: 10px;
    }
    
    .chatbotOpen {      
        width: 320px;
    }
    
    .chatBotContainer {      
        width: 300px;        
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .chatbot {       
        top: 20px;       
        bottom: 20px;
    }
    
    .chatbotOpen {      
        width: 370px;
    }
    
    .chatBotContainer {      
        width: 340px;        
    }
}

.helpTextContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 200px;
    overflow-y: auto;
    max-height: 100%;
    padding: 0 30px;
}

.chatbotCloseButton {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 30px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.4));
}

.chatbotPrevButton {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 30px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.4));
}

.chatbotContinueButton {
    position: absolute;
    right: 12px;
    bottom: 5px;
    height: 25px;
    cursor: pointer;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    line-height: 25px;
    filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.4));
}

.chatBotImgContainer {
    background-color: rgba(255, 255, 255, 0.2);
    width: 50px;
    height: 100px;
    left: -50px;
    border-radius: 100px 0 0 100px;
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;    
}

.chatBotImg {
    width: 36px;
    height: 36px;
    margin-left: 10px;
    filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.4));
}

.chatBotQuestion {
    background-color: white;
    color: #1E1E1E;
    line-height: 30px;
    text-align: right;
    padding: 10px 20px;
    border-radius: 25px 25px 0px 25px;
    cursor: pointer;
    margin-left: auto;
    width: fit-content;
}

.hideChatBotQuestion {
    display: none;
}

.chatBotAnswer {
    background-color: #190637;
    color: white;
    line-height: 30px;
    text-align: left;
    padding: 10px 20px;
    border-radius: 25px 25px 25px 0px;
    margin-right: auto;
    width: fit-content;
}

.chatBotQuestion:hover {
    opacity: 0.7;
}
