.videoContainers {
    position: fixed;
    top:10%;
    left:0%;
    width: 100%;
    display: flex;    
    align-items: center;
    height: 160px;
    z-index: 10;
    overflow-x: auto;
    overflow-y: hidden;
}

.item {
    margin-right: 10px;
}

.item:first-child {
    margin-left: auto;    
}

.item:last-child {
    margin-right: auto;
}

.localVideoContainer, .remoteVideoContainer{   
    width: 200px;
    min-width: 180px;
    height: 130px;
    background: #656565;
    border-radius: 16px;   
    display: flex;
    align-items: center; 
    position: relative;    
}

.videoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    

    div {       
        border-radius: 16px;
        position: absolute!important;
    }
}

.videoContainerHided {
    composes: videoContainer;
    display: none;
}

.videoAvatar {

    width: 54px;
    height: 54px;
}

.videoActions {

    display: flex;
    width: 80px;
    height: 18px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 11;

    img {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
}

.emptyImgContainer {
    width: 18px;
    height: 18px;
    cursor: pointer;
}